import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { Input, InputNumber, Radio } from "antd";
import { IBasicProps } from "../../IBasicProps";
import { ReactComponent as RightArrow } from "../../images/arrow-right.svg";
import { IField, IQuestionnaire, IRadioOption } from "../../common/model/IQuestionnaire";
import { generatePdf, getQuestionnaire } from "../../common/service/contractService";
import { handleError, notifyError } from "../../common/notificationService";
import { WEB_BASE_URL } from "../../constants";
import { AccessControl } from "../../accessControl/AccessControl";
import { Loader } from "../../common/Loader";
import { AntdIcon } from "../../common/AntdIcon";
import { GeneratePdfRequest } from "./model/GeneratePdfRequest";
import { QuestionnaireCheckbox } from "./QuestionnaireCheckbox";
import { Questionnaire, toQuestionnaire } from "./model/Questionnaire";
import "./QuestionnaireViewer.css";

interface IQuestionnaireTextLabelProps {
	initialValue: string;
	label: string;
	name: string;
	fillQuestionnaireValue: (fieldName: string, value: string) => void;
	previousValue?: string;
}

const QuestionnaireTextLabel: FC<IQuestionnaireTextLabelProps> = props => {
	const getInitialValue = (): string => {
		if (props.previousValue) {
			return props.previousValue;
		}

		return props.initialValue;
	};

	const [value, setValue] = useState(getInitialValue());

	useEffect(() => {
		props.fillQuestionnaireValue(props.name, value);
	}, [value]);

	return (
		<label className="flex items-center">
			<span className="w-1/4">{props.label}</span>
			<Input
				className="w-3/4 h-10"
				type="text"
				name={props.name}
				value={value}
				onChange={e => setValue(e.target.value)}
				style={{ borderRadius: "5px" }}
			/>
		</label>
	);
};

interface IQuestionnaireNumberLabelProps {
	initialValue: number;
	label: string;
	name: string;
	min: number;
	max: number;
	step: number;
	fillQuestionnaireValue: (fieldName: string, value: string) => void;
	previousValue?: number;
	isAddonBeforeIcon?: boolean;
	addonBefore?: string;
	isAddonAfterIcon?: boolean;
	addonAfter?: string;
}

const QuestionnaireNumberLabel: FC<IQuestionnaireNumberLabelProps> = props => {
	const getInitialValue = (): number => {
		if (props.previousValue) {
			return props.previousValue;
		}

		return props.initialValue;
	};

	const [value, setValue] = useState<number | "">(getInitialValue());

	useEffect(() => {
		props.fillQuestionnaireValue(props.name, value.toString());
	}, [value]);

	const roundValueToStep = (val: number): number => Math.round(val / props.step) * props.step;

	const adjustValueToRange = (val: number): number => {
		if (val < props.min) {
			return props.min;
		} else if (val > props.max) {
			return props.max;
		} else {
			return val;
		}
	};

	const handleInput = (val: string | number | null): void => {
		if (val === null) {
			return;
		}

		const inputValue = typeof val === "string" ? val.replace(/^0+/, "") : val.toString();

		if (inputValue === "") {
			setValue("");
			return;
		}

		const parsedValue = parseFloat(inputValue);

		if (isNaN(parsedValue)) {
			return;
		}

		setValue(parsedValue);
	};

	const getAddon = (isIcon?: boolean, addon?: string): ReactNode => {
		if (!addon) {
			return null;
		}

		if (isIcon) {
			return <AntdIcon name={addon} className="w-5 h-5 translate-y-0.5" size="14" />;
		}

		return addon;
	};

	return (
		<label className="flex items-center">
			<span className="w-1/4">{props.label}</span>
			<InputNumber
				className="w-3/4 h-10"
				addonBefore={getAddon(props.isAddonBeforeIcon, props.addonBefore)}
				addonAfter={getAddon(props.isAddonAfterIcon, props.addonAfter)}
				value={value}
				name={props.name}
				min={props.min}
				max={props.max}
				step={props.step}
				onChange={handleInput}
				onBlur={() => setValue(adjustValueToRange(roundValueToStep(value as number)))}
				bordered
			/>
		</label>
	);
};

interface IQuestionnaireRadioLabelProps {
	initialValue: string;
	label: string;
	name: string;
	options: IRadioOption[];
	fillQuestionnaireValue: (fieldName: string, value: string) => void;
	previousValue?: string;
}

const QuestionnaireRadioLabel: FC<IQuestionnaireRadioLabelProps> = props => {
	const getInitialValue = (): string => {
		if (props.previousValue) {
			return props.previousValue;
		}

		return props.initialValue;
	};

	const [value, setValue] = useState(getInitialValue());

	useEffect(() => {
		props.fillQuestionnaireValue(props.name, value);
	}, [value]);

	return (
		<label className="flex items-start">
			<span className="w-1/4">{props.label}</span>
			<div className="flex flex-col">
				{props.options.map((option, index) => (
					<div className="whitespace-nowrap" key={`inp-${index + 1}`}>
						<Radio
							className="w-4 hover:cursor-pointer"
							key={`inp-${index + 1}`}
							name={props.name}
							checked={value === option.value}
							onClick={() => setValue(option.value)}
							onChange={() => {
								setValue(option.value);
							}}
						>
							{option.label}
						</Radio>
					</div>
				))}
			</div>
		</label>
	);
};

interface IQuestionnaireViewerProps extends IBasicProps {
	contractId?: string;
	fetchContractDetails: () => void;
}

export const QuestionnaireViewer: FC<IQuestionnaireViewerProps> = props => {
	const [questionnaire, setQuestionnaire] = useState<IQuestionnaire>();
	const [filledQuestionnaire, setFilledQuestionnaire] = useState<Questionnaire[]>([]);
	const [generatePdfLoading, setGeneratePdfLoading] = useState(false);
	const [formValid, setFormValid] = useState(true);

	useEffect(() => {
		if (props.contractId) {
			getQuestionnaire(props.contractId)
				.then(response => {
					setQuestionnaire(response);
					setFilledQuestionnaire(toQuestionnaire(response));
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [props.contractId]);

	const setValueForGivenField = (fieldName: string, newValue: string): void => {
		setFilledQuestionnaire(prevFilledQuestionnaire => {
			const foundValueIndex = prevFilledQuestionnaire.findIndex(q => q.fieldName === fieldName);

			if (foundValueIndex >= 0) {
				const newQuestionnaire = [...prevFilledQuestionnaire];
				newQuestionnaire[foundValueIndex] = new Questionnaire(fieldName, newValue);
				return newQuestionnaire;
			} else {
				return prevFilledQuestionnaire;
			}
		});
	};

	const getPreviousValueIfExist = (key: string): string | undefined => {
		if (questionnaire && key in questionnaire?.previousValues) {
			return questionnaire.previousValues[key];
		}

		return undefined;
	};

	const getPreviousValueAsNumberIfExist = (key: string): number | undefined => {
		if (questionnaire && key in questionnaire?.previousValues) {
			return +questionnaire.previousValues[key];
		}

		return undefined;
	};

	const isLocationQuestionnaire = (): boolean =>
		questionnaire?.fields.some(field => field.fieldType === "LOCATIONOPTIONS") || false;

	const getField = (field: IField): ReactElement => {
		if (field.fieldType === "TEXT") {
			return (
				<QuestionnaireTextLabel
					initialValue={field.initialValue}
					label={field.label}
					name={field.name}
					key={field.name}
					fillQuestionnaireValue={setValueForGivenField}
					previousValue={getPreviousValueIfExist(field.name)}
				/>
			);
		} else if (field.fieldType === "NUMBER") {
			return (
				<QuestionnaireNumberLabel
					initialValue={+field.initialValue}
					label={field.label}
					name={field.name}
					min={field.numberOptions.min}
					max={field.numberOptions.max}
					step={field.numberOptions.step}
					fillQuestionnaireValue={setValueForGivenField}
					key={field.name}
					previousValue={getPreviousValueAsNumberIfExist(field.name)}
					isAddonBeforeIcon={field.numberOptions.isAddonBeforeIcon}
					addonBefore={field.numberOptions.addonBefore}
					isAddonAfterIcon={field.numberOptions.isAddonAfterIcon}
					addonAfter={field.numberOptions.addonAfter}
				/>
			);
		} else if (field.fieldType === "RADIO") {
			return (
				<QuestionnaireRadioLabel
					initialValue={field.initialValue}
					label={field.label}
					name={field.name}
					options={field.radioOptions}
					fillQuestionnaireValue={setValueForGivenField}
					key={field.name}
					previousValue={getPreviousValueIfExist(field.name)}
				/>
			);
		} else if (field.fieldType === "LOCATIONOPTIONS") {
			return (
				<QuestionnaireCheckbox
					initialValue={field.initialValue}
					label={field.label}
					name={field.name}
					options={field.checkboxes}
					locations={questionnaire?.locations}
					fillQuestionnaireValue={setValueForGivenField}
					setFormValid={setFormValid}
					key={field.name}
					previousValue={getPreviousValueIfExist(field.name)}
				/>
			);
		} else {
			return <></>;
		}
	};

	const generatePdfFromQuestionnaire = (): void => {
		if (!formValid) {
			notifyError(props.t(isLocationQuestionnaire() ? "locations.not.provided" : "questionnaire.not.provided"));
			return;
		}
		setGeneratePdfLoading(true);
		if (questionnaire?.reportKey && props.contractId) {
			generatePdf(props.contractId, new GeneratePdfRequest(questionnaire?.reportKey, filledQuestionnaire))
				.then(() => {
					props.fetchContractDetails();
					props.navigate(`${WEB_BASE_URL}contracts/${props.contractId}/details`);
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				})
				.finally(() => setGeneratePdfLoading(false));
		}
	};

	return (
		<div className="contract-details-questionnaire-container flex flex-col justify-between">
			<div className="mt-10">
				<>
					{questionnaire?.fields && questionnaire?.fields.length > 0 ? (
						<>
							<span className="text-2xl">{props.t("data.required.for.contract")}</span>
							<div className="flex flex-col gap-4 mt-10">{questionnaire?.fields.map(field => getField(field))}</div>
						</>
					) : !questionnaire?.reportKey ? (
						<div className="w-full flex justify-center">
							<span className="text-2xl">{props.t("questionnaire.not.provided")}</span>
						</div>
					) : (
						<div className="w-full flex justify-center text-center">
							<span className="text-2xl">{props.t("empty.questionnaire.info")}</span>
						</div>
					)}
				</>
			</div>
			<div className="mb-5">
				<div className="flex justify-end">
					<AccessControl
						allowedPermission={[
							"financialAdvisor",
							"superAdmin",
							"editor",
							"releaseAdmin",
							"regionalManager",
							"confirmationAdmin",
						]}
						{...props}
					>
						{questionnaire?.reportKey && (
							<button className="primary-with-icon" onClick={() => generatePdfFromQuestionnaire()}>
								<span>{props.t("further")}</span>
								<RightArrow className="fill-white" />
							</button>
						)}
					</AccessControl>
				</div>
			</div>
			{generatePdfLoading && <Loader size={64} coverBackground={true} />}
		</div>
	);
};
