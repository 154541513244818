import axios, { AxiosRequestHeaders } from "axios";
import { API_BASE_URL } from "../../constants";
import { authHeader, get, post } from "../../security/secureClient";
import { GeneratePdfRequest } from "../../contract/details/model/GeneratePdfRequest";
import { IQuestionnaire } from "../model/IQuestionnaire";

export const addNewContract = (
	releaseState: string,
	name: string,
	description: string,
	interval: string,
	infiniteInterval: boolean,
	expirationDate: Date,
	visibilityGroups: string[],
	questionnaire: unknown,
	attachments: File[],
	mandatoryDownload: boolean,
	checkboxes: string[],
	allowRevocation: boolean,
	revocationDeadline: string,
	allowCancellation: boolean,
	cancellationMonths: string,
	cancellationDay: string,
	allowRecertification: boolean,
	recertificationPeriod: boolean,
	recertificationPeriodStart: string | undefined,
	recertificationPeriodEnd: string | undefined,
	sendRegional: boolean,
	emailAddresses: string[],
	emailInfoText: string,
	category: string,
	releaseNecessary: boolean,
	previewImage?: File
): Promise<any> => {
	const formData = new FormData();
	formData.append("releaseState", releaseState);
	formData.append("name", name);
	formData.append("description", description);
	formData.append("interval", interval);
	formData.append("infiniteInterval", JSON.stringify(infiniteInterval));
	formData.append("expirationDate", JSON.stringify(expirationDate));
	formData.append("visibilityGroups", visibilityGroups.join(","));
	formData.append("mandatoryDownload", JSON.stringify(mandatoryDownload));
	formData.append("checkboxes", JSON.stringify(checkboxes));
	formData.append("allowRevocation", JSON.stringify(allowRevocation));
	formData.append("recertificationPeriod", JSON.stringify(recertificationPeriod));
	formData.append("recertificationPeriodStart", recertificationPeriodStart || "");
	formData.append("recertificationPeriodEnd", recertificationPeriodEnd || "");
	formData.append("revocationDeadline", revocationDeadline);
	formData.append("allowCancellation", JSON.stringify(allowCancellation));
	formData.append("cancellationMonths", cancellationMonths);
	formData.append("cancellationDay", cancellationDay);
	formData.append("allowRecertification", JSON.stringify(allowRecertification));
	formData.append("sendRegional", JSON.stringify(sendRegional));
	formData.append("emailAddresses", emailAddresses.join(","));
	formData.append("emailInfoText", emailInfoText);
	formData.append("category", category);
	formData.append("releaseNecessary", JSON.stringify(releaseNecessary));

	if (previewImage !== undefined) {
		formData.append("previewImage", previewImage);
	} else {
		formData.append("previewImage", JSON.stringify(null));
	}

	if (attachments.length > 0) {
		attachments.forEach(att => {
			formData.append("attachments", att);
		});
	}

	return post("contract", formData, {});
};

export const putContract = (
	contractId: string,
	releaseState: string,
	name: string,
	description: string,
	interval: string,
	infiniteInterval: boolean,
	expirationDate: Date,
	visibilityGroups: string[],
	attachments: File[],
	mandatoryDownload: boolean,
	checkboxes: string[],
	allowRevocation: boolean,
	revocationDeadline: string,
	allowCancellation: boolean,
	cancellationMonths: string,
	cancellationDay: string,
	allowRecertification: boolean,
	recertificationPeriod: boolean,
	recertificationPeriodStart: string | undefined,
	recertificationPeriodEnd: string | undefined,
	sendRegional: boolean,
	emailAddresses: string[],
	emailInfoText: string,
	category: string,
	releaseNecessary: boolean,
	previewImage?: File
): Promise<any> => {
	const formData = new FormData();
	formData.append("contractId", contractId);
	formData.append("releaseState", releaseState);
	formData.append("name", name);
	formData.append("description", description);
	formData.append("interval", interval);
	formData.append("infiniteInterval", JSON.stringify(infiniteInterval));
	formData.append("expirationDate", JSON.stringify(expirationDate));
	formData.append("visibilityGroups", visibilityGroups.join(","));
	formData.append("mandatoryDownload", JSON.stringify(mandatoryDownload));
	formData.append("checkboxes", JSON.stringify(checkboxes));
	formData.append("allowRevocation", JSON.stringify(allowRevocation));
	formData.append("revocationDeadline", revocationDeadline);
	formData.append("allowCancellation", JSON.stringify(allowCancellation));
	formData.append("cancellationMonths", cancellationMonths);
	formData.append("cancellationDay", cancellationDay);
	formData.append("allowRecertification", JSON.stringify(allowRecertification));
	formData.append("recertificationPeriod", JSON.stringify(recertificationPeriod));
	formData.append("recertificationPeriodStart", recertificationPeriodStart || "");
	formData.append("recertificationPeriodEnd", recertificationPeriodEnd || "");
	formData.append("sendRegional", JSON.stringify(sendRegional));
	formData.append("emailAddresses", emailAddresses.join(","));
	formData.append("emailInfoText", emailInfoText);
	formData.append("category", category);
	formData.append("releaseNecessary", JSON.stringify(releaseNecessary));

	if (previewImage !== undefined) {
		formData.append("previewImage", previewImage);
	} else {
		formData.append("previewImage", JSON.stringify(null));
	}

	if (attachments.length > 0) {
		attachments.forEach(att => {
			formData.append("attachments", att);
		});
	}

	return post("contract/put", formData, {});
};

export const deleteContract = (contractId: string): Promise<any> =>
	axios.request({
		url: `${API_BASE_URL}contract/${contractId}`,
		method: "DELETE",
		headers: authHeader() as AxiosRequestHeaders,
	});

export const sendApproveContract = (contractId: string): Promise<any> => post(`contract/${contractId}/approve`, {}, {});

export const sendApproveApproval = (contractId: string, affectedUserId: string): Promise<any> =>
	post(`contract/${contractId}/approve-approval`, { affectedUserId }, {});

export const sendRejectContract = (contractId: string): Promise<any> => post(`contract/${contractId}/reject`, {}, {});

export const sendRejectApproval = (contractId: string, reason: string, affectedUserId: string): Promise<any> =>
	post(`contract/${contractId}/reject-approval`, { reason, affectedUserId }, {});

export const sendReleaseContract = (contractId: string): Promise<any> => post(`contract/${contractId}/release`, {}, {});

export const sendReleaseContractWithNotification = (contractId: string): Promise<any> =>
	post(`contract/${contractId}/release/with-notification`, {}, {});

export const sendRevokeContract = (contractId: string): Promise<any> => post(`contract/${contractId}/revoke`, {}, {});
export const sendCancelContract = (contractId: string): Promise<any> => post(`contract/${contractId}/cancel`, {}, {});

export const sendRecertifyContract = (contractId: string): Promise<any> =>
	post(`contract/${contractId}/recertify`, {}, {});

export const sendRecertifyCancel = (contractId: string): Promise<any> =>
	post(`contract/${contractId}/cancel-recertify`, {}, {});

export const getEventNameBasedOnReleaseState = (releaseState: string): string => {
	switch (releaseState) {
		case "COMMISSIONED":
			return "commissioned";
		case "DRAFT":
			return "createDraft";
		case "INACTIVE":
			return "setInactive";
		case "INVALID":
			return "setInvalid";
		case "TO_BE_RELEASED":
			return "sendToRelease";
		case "RELEASED":
			return "release";
		default:
			return "unknown";
	}
};

export const getLatestEventForType = (contractId: string, type: string): Promise<any> =>
	get(`contract/${contractId}/latest/${type}`);

export const getLatestEventForAffectedUser = (contractId: string, affectedUserId: string): Promise<any> =>
	get(`contract/${contractId}/user/${affectedUserId}/event`);

export const getQuestionnaire = (contractId: string): Promise<IQuestionnaire> =>
	get(`contract/${contractId}/questionnaire`);

export const generatePdf = (contractId: string, generatePdfRequest: GeneratePdfRequest): Promise<any> =>
	post(`contract/${contractId}/questionnaire/generate-pdf`, { requestData: generatePdfRequest }, {});
